@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/indigo.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/purple.css';
@import '@radix-ui/colors/violet.css';

.NavigationMenuRoot {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;

  .NavigationMenuList {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;

    .NavigationMenuTrigger,
    .NavigationMenuLink {
      padding: 8px;
      outline: none;
      user-select: none;
      font-weight: 400;
      line-height: normal;
      color: #565656;
      font-size: 14px;
    }

    .NavigationMenuTrigger {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .NavigationMenuLink {
      display: block;
      text-decoration: none;
      font-size: 15px;
      line-height: 1;
    }

    .NavigationMenuContent {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      animation-duration: 250ms;
      animation-timing-function: ease;
      @media only screen and (min-width: 600px) {
        width: auto;
      }

      &[data-motion='from-start'] {
        animation-name: enterFromLeft;
      }

      &[data-motion='from-end'] {
        animation-name: enterFromRight;
      }

      &[data-motion='to-start'] {
        animation-name: exitToLeft;
      }

      &[data-motion='to-end'] {
        animation-name: exitToRight;
      }
    }
  }


  .NavigationMenuIndicator {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 10px;
    top: 100%;
    overflow: hidden;
    z-index: 1;
    transition: width, transform 250ms ease;

    &[data-state='visible'] {
      animation: fadeIn 200ms ease;
    }

    &[data-state='hidden'] {
      animation: fadeOut 200ms ease;
    }
  }

  .NavigationMenuViewport {
    border-radius:10px;
    position: relative;
    transform-origin: top center;
    margin-top: 10px;
    width: 100%;
    background-color: white;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.20);
    height: var(--radix-navigation-menu-viewport-height);
    transition: width, height, 300ms ease;

    &[data-state='open'] {
      animation: scaleIn 200ms ease;
    }

    &[data-state='closed'] {
      animation: scaleOut 200ms ease;
    }

    @media only screen and (min-width: 600px) {
      //width: var(--radix-navigation-menu-viewport-width);
      width: 90%;
    }

    .List {
      border-radius:10px;
      position: relative;
      width: 300px;
      padding: 22px 0;
      margin: 0;
      background: #FFF;

      .ListItemLink {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        padding: 0 22px;
        height: 32px;
        position: relative;
        display: block;
        outline: none;
        text-decoration: none;
        user-select: none;
        font-size: 14px;
        line-height: 34px;
        font-weight: 400;

        &__active {
          &::before, &::after {
            content: "";
            position: absolute;
            animation: fadeIn 500ms ease;
            top: 50%;
            transform: translateY(-50%);
          }

          &::before {
            width: 8px;
            height: 50%;
            left: 0;
            background: #D9D9D9;
          }

          &::after {
            background: url("/arrowRight.svg");
            background-position: center;
            background-repeat: no-repeat;
            width: 34px;
            height: 10px;
            right: 0;
          }
        }
      }

      .Submenu {
        border-left : 1px solid #E5E5E5;
        overflow: hidden;
        height: 100%;
        position: absolute;
        right: -90%;
        top: 0;
        width: 275px;
        padding: 22px 0;
        margin: 0;
        background: #FFF;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 7px 2px 10px 0px rgba(0, 0, 0, 0.20);

        li {
          cursor: pointer;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
          max-width: 260px;
          padding: 0 12px;
          height: 32px;
          display: block;
          outline: none;
          text-decoration: none;
          user-select: none;
          font-size: 14px;
          line-height: 34px;
          font-weight: 400;
        }

        &[data-state='open'] {
          animation: scaleIn 200ms ease;
        }

        &[data-state='closed'] {
          animation: scaleOut 200ms ease;
        }
      }
    }
  }

  .ViewportPosition {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    top: 100%;
    left: 0;
    perspective: 2000px;
  }

  .CaretDown {
    position: relative;
    color: #565656;
    top: 1px;
    transition: transform 250ms ease;
  }

  [data-state='open'] > .CaretDown {
    transform: rotate(-180deg);
  }

  .Arrow {
    position: relative;
    top: 65%;
    background-color: white;
    width: 15px;
    height: 10px;
    transform: rotate(45deg);
    border-top-left-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}


@keyframes enterFromRight {
  from {
    opacity: 0;
    transform: translateX(200px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes enterFromLeft {
  from {
    opacity: 0;
    transform: translateX(-200px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes exitToRight {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(200px);
  }
}

@keyframes exitToLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-200px);
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: rotateX(-30deg) scale(0.9);
  }
  to {
    opacity: 1;
    transform: rotateX(0deg) scale(1);
  }
}

@keyframes scaleOut {
  from {
    opacity: 1;
    transform: rotateX(0deg) scale(1);
  }
  to {
    opacity: 0;
    transform: rotateX(-10deg) scale(0.95);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
