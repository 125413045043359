.DropdownMenuContent,
.DropdownMenuSubContent {
    min-width: 230px;
    background-color: white;
    border-radius: 6px;
    padding: 5px;
    box-shadow: 0 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
}

[data-highlighted] > .squareMarker {
    background-color: #c9cbcc;;
}

[data-disabled] .squareMarker {
    background-color: var(--mauve-8);
}

.squareMarker {
    width: 8px;
    height: 14px;
    margin-right: 2rem;
    margin-left: -1.9rem;
}


.DropdownMenuSubContent {
    margin: 0;
}

.DropdownMenuContent[data-side='top'],
.DropdownMenuSubContent[data-side='top'] {
    animation-name: slideDownAndFade;
}

.DropdownMenuContent[data-side='right'],
.DropdownMenuSubContent[data-side='right'] {
    animation-name: slideLeftAndFade;
}

.DropdownMenuContent[data-side='bottom'],
.DropdownMenuSubContent[data-side='bottom'] {
    animation-name: slideUpAndFade;
}

.DropdownMenuContent[data-side='left'],
.DropdownMenuSubContent[data-side='left'] {
    animation-name: slideRightAndFade;
}

.DropdownMenuItem,
.DropdownMenuCheckboxItem,
.DropdownMenuRadioItem,
.DropdownMenuSubTrigger {
    font-size: 14px;
    line-height: 1;
    color: #4D5158;
    border-radius: 3px;
    display: flex;
    align-items: center;
    height: 25px;
    padding: 0 5px;
    position: relative;
    padding-left: 25px;
    user-select: none;
    outline: none;
    margin: 2px 0;
}

.DropdownMenuItem[data-disabled],
.DropdownMenuCheckboxItem[data-disabled],
.DropdownMenuRadioItem[data-disabled],
.DropdownMenuSubTrigger[data-disabled] {
    color: var(--mauve-8);
    pointer-events: none;
}

.DropdownMenuArrow {
    fill: white;
}

.IconButton, .IconButton:hover {
    font-family: inherit;
    border-radius: 5px;
    width: 10%;
    min-width: 190px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    color: #565656;
    background-color: #FAFAFA;
}

.RightSlot {
    margin-left: auto;
    padding-left: 20px;
    color: white;
}

[data-highlighted] > .RightSlot {
    color: var(--mauve-11);
}

[data-disabled] .RightSlot {
    color: var(--mauve-8);
}

@keyframes slideUpAndFade {
    from {
        opacity: 0;
        transform: translateY(2px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideRightAndFade {
    from {
        opacity: 0;
        transform: translateX(-2px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideDownAndFade {
    from {
        opacity: 0;
        transform: translateY(-2px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideLeftAndFade {
    from {
        opacity: 0;
        transform: translateX(2px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
